import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import LocationContext from '../context/LocationContext';
import AuthContext from '../context/AuthContext';

const LocationLayout = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { userData } = useContext(AuthContext);

    const [locationId, setLocationId] = useState(searchParams.get('locationId'));

    const updateLocationId = (locationId) => {
        setLocationId(locationId);
    };

    useEffect(() => {
        setSearchParams((prev) => {
            if (locationId) {
                prev.set('locationId', locationId);
            } else {
                searchParams.delete('locationId');
            }
            return prev;
        });
    }, [locationId, searchParams, setSearchParams]);

    const { company_id, defaultLocation } = userData;

    useEffect(() => {
        setSearchParams((prev) => {
            if (defaultLocation) {
                prev.set('locationId', defaultLocation);
                setLocationId(defaultLocation);
            } else {
                searchParams.delete('locationId');
            }
            return prev;
        });
    }, [company_id, defaultLocation, searchParams, setSearchParams]);

    return (
        <LocationContext.Provider value={{locationId, updateLocationId}}>
            <Outlet />
        </LocationContext.Provider>
    );
};

export default LocationLayout;
