import React, { useState, useRef, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMutation from '../../hooks/useMutation';

import useQuery from '../../hooks/useQuery';

import Viewer from '../../components/Viewer';

import ExpandTable from './ExpandTable';
import PenDetails from './PenDetails';
import CattleDetails from './CattleDetails';

import useSubscription from '../../hooks/useSubscription';

import Client from '../../apis/Client';

import SocketContext from '../../context/SocketContext';

import css from './styles.module.scss';

function useShowIds() {
  let settings = localStorage.getItem('settings');
  settings = JSON.parse(settings) || { showIds: false };

  const [showIds, setShowIds] = useState(settings.showIds);

  function updateShowIds(bool) {
    settings.showIds = bool;
    localStorage.setItem('settings', JSON.stringify(settings));
    setShowIds(bool);
  }

  return [showIds, updateShowIds];
}

function PenManager() {
  const [searchParams, setSearchParams] = useSearchParams();

  const socketClient = useContext(SocketContext);

  const viewerRef = useRef();

  const locationId = searchParams.get('locationId');
  const cattleId = searchParams.get('cattleId');
  const deviceId = searchParams.get('deviceId');

  const [isDebugView, setIsDebugView] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selectedId, setSelectedId] = useState(cattleId);
  const [deviceData, setDeviceData] = useState(null);
  const [deviceCattle, setDeviceCattle] = useState([]);
  const [selectedCattle, setSelectedCattle] = useState(null);

  const [showIds, setShowIds] = useShowIds();

  const [shouldDrawMovement, setShouldDrawMovement] = useState(true);
  const [shouldDrawHeadPos, setShouldDrawHeadPos] = useState(true);

  function updateCattleId(id) {
    setSelectedId(id);

    if (viewerRef.current) {
      viewerRef.current.setActiveId(id);
    }

    if (!id) {
      setSearchParams({ locationId, deviceId });
      return;
    }

    if (id === cattleId) {
      return;
    }

    setSearchParams({
      locationId,
      deviceId,
      cattleId: id
    });
  }

  function updateDeviceId(id) {
    if (id === deviceId) {
      return;
    }

    setSearchParams({
      locationId,
      deviceId: id
    });
  }

  const { isLoading: isDeviceLoading, refetch: refetchDeviceData } = useQuery({
    queryKey: ['get_device_data', locationId, deviceId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/devices/${deviceId || 'null'}`),
    onSuccess: (data) => {
      updateDeviceId(data.id);
      fetchDeviceCattle(data);
      setDeviceData(data);
    }
  });

  const { mutate: fetchDeviceCattle, isLoading: isDeviceCattleLoading } = useMutation({
    mutationKey: 'get_device_cattle',
    mutationFn: async (data) => Client.get(`/api/v1/producers/locations/${locationId}/cattle?monitoring_area=${data?.monitoring_area_id}&all=true`),
    onSuccess: (data) => {
      setDeviceCattle(data);
    }
  });

  const { isLoading: isSelectedLoading, refetch: refetchSelected } = useQuery({
    queryKey: ['get_cattle_view_details', cattleId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/cattle/${cattleId}?mug=true&health_notes=true&note_details=true&monitoring_area=${deviceData?.monitoring_area_id}`),
    onSuccess: (data) => {
      setSelectedCattle(data);
    },
    enabled: !!cattleId && !!deviceData,
  });

  function refreshData() {
    refetchDeviceData();
    refetchSelected();
  }

  const areaId = deviceData?.monitoring_area_id;

  useSubscription(socketClient, 'area-mug-post', areaId, (data) => {
    const isSelected = selectedCattle?.id === data.id;
    if (isSelected) {
      setSelectedCattle({
        ...selectedCattle,
        mug_id: data.mug_id,
        mug: data.mug,
        mug_debug_id: data.mug_debug_id,
        mug_debug: data.mug_debug
      });
    }

    const existingIndex = deviceCattle.findIndex(c => c.id === data.id);
    if (existingIndex >= 0) {
      const updated = {
        ...deviceCattle[existingIndex],
        mug_id: data.mug_id,
        mug: data.mug,
        mug_debug_id: data.mug_debug_id,
        mug_debug: data.mug_debug
      };

      setDeviceCattle([
        ...deviceCattle.slice(0, existingIndex),
        updated,
        ...deviceCattle.slice(existingIndex + 1)
      ]);
    } else {
      setDeviceCattle([...deviceCattle, data]);
    }
  });

  const deviceIP = deviceData?.device_ip;
  const liveViewMode = deviceData?.liveViewMode;
  const isRtsp = deviceData?.is_rtsp;

  const noNextCamera = deviceData?.next_device_id === deviceData?.id;

  const expandBtn = (
    <button
      onClick={() => setShowTable(!showTable)}
      disabled={isDeviceLoading}
      className={css.btnExpand}
      style={{
        backgroundColor: showTable ? '#3099FD' : '#fff',
        color: showTable ? '#fff' : '#3099FD',
      }}
    >
      <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1536 5.10769H5.16458C4.69712 5.10769 4.31818 5.58983 4.31818 6.18462C4.31818 6.7794 4.69712 7.26154 5.16458 7.26154H18.1536C18.6211 7.26154 19 6.7794 19 6.18462C19 5.58983 18.6211 5.10769 18.1536 5.10769Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M18.1536 0H5.16458C4.69712 0 4.31818 0.482139 4.31818 1.07692C4.31818 1.67171 4.69712 2.15385 5.16458 2.15385H18.1536C18.6211 2.15385 19 1.67171 19 1.07692C19 0.482139 18.6211 0 18.1536 0Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M18.1536 9.84615H5.16458C4.69712 9.84615 4.31818 10.3283 4.31818 10.9231C4.31818 11.5179 4.69712 12 5.16458 12H18.1536C18.6211 12 19 11.5179 19 10.9231C19 10.3283 18.6211 9.84615 18.1536 9.84615Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M1.07955 2.15385C1.67576 2.15385 2.15909 1.67169 2.15909 1.07692C2.15909 0.482155 1.67576 0 1.07955 0C0.483329 0 0 0.482155 0 1.07692C0 1.67169 0.483329 2.15385 1.07955 2.15385Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M1.07955 7.26154C1.67576 7.26154 2.15909 6.77938 2.15909 6.18462C2.15909 5.58985 1.67576 5.10769 1.07955 5.10769C0.483329 5.10769 0 5.58985 0 6.18462C0 6.77938 0.483329 7.26154 1.07955 7.26154Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M1.07955 12C1.67576 12 2.15909 11.5178 2.15909 10.9231C2.15909 10.3283 1.67576 9.84615 1.07955 9.84615C0.483329 9.84615 0 10.3283 0 10.9231C0 11.5178 0.483329 12 1.07955 12Z" fill={showTable ? '#fff' : '#3099FD'} />
      </svg>
      Expand Table
    </button>
  );

  const panelEl = selectedId
    ? <CattleDetails
      locationId={locationId}
      areaId={areaId}
      cattleId={selectedId}
      updateCattleId={(id) => updateCattleId(id)}
      data={selectedCattle}
      isLoading={isSelectedLoading}
      refetch={refreshData}
    />
    : <PenDetails
      isDebugView={isDebugView}
      setIsDebugView={setIsDebugView}
      prevDevice={() => updateDeviceId(deviceData?.prev_device_id)}
      nextDevice={() => updateDeviceId(deviceData?.next_device_id)}
      data={deviceData}
      refetch={refetchDeviceData}
      noNextCamera={noNextCamera}
      locationId={locationId}
      isLoading={isDeviceLoading}
      showIds={showIds}
      setShowIds={setShowIds}
      shouldDrawMovement={shouldDrawMovement}
      setShouldDrawMovement={setShouldDrawMovement}
      shouldDrawHeadPos={shouldDrawHeadPos}
      setShouldDrawHeadPos={setShouldDrawHeadPos}
    />;

  return (
    <div className={`${css.container}`}>
      <Viewer
        ref={viewerRef}
        deviceId={deviceId}
        deviceIP={deviceIP}
        isJSmpeg={isRtsp}
        className={css.liveViewContainer}
        liveViewMode={liveViewMode}
        customEls={expandBtn}
        onClick={updateCattleId}
        showIds={showIds}
        shouldDrawMovement={shouldDrawMovement}
        shouldDrawHeadPos={shouldDrawHeadPos}
        isDebugView={isDebugView}
        socketClient={socketClient}
      >
        <ExpandTable
          show={showTable}
          setShow={setShowTable}
          selected={selectedId}
          setSelected={updateCattleId}
          data={deviceCattle}
          isDebugView={isDebugView}
        />
      </Viewer>
      {panelEl}
    </div>
  );
}

export default PenManager;
