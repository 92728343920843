import React from 'react';

import css from '../styles.module.scss';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';

const MetricItem = ({ title = '', p }) => {
    return (
        <div className={css.item}>
            <h3>{title}</h3>
            <p>{p}</p>
        </div>
    );
};

function CustomerMetrics(props) {
  const { location } = props;

  return (
    <div id={css.metrics} className='tableContain'>
      <PageSubTitle
        title="Location Metrics"
      />

      <div id={css.metricsNums}>
        <MetricItem
          title="Total Head"
          p={location?.expectedHeadCount || 0}
        />
        <MetricItem
          title="Apps Installed"
          p={(location?.appsInstalled || []).length}
        />
        <MetricItem title="Avg. Monthly Expense" p={10} />
        <MetricItem
          title={'Total Users'}
          p={(location?.users || location?.accounts || []).length}
        />
        <MetricItem
          title={'Total Poles'}
          p={location?.numOfPoles || (location?.poles || []).length}
        />
      </div>
    </div>
  );
};

export default CustomerMetrics;
