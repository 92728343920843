import React from 'react';

import InputBase from '../InputBase';

import css from './styles.module.scss';

function CustomSlider(props) {
    const {
        name,
        label,
        value,
        onChange,
        onBlur,
        formik,
        disabled,
        className
    } = props;

    return (
        <InputBase {...props}>
            <label className={css.switch}>
                <input
                    id={name}
                    name={name}
                    type='checkbox'
                    checked={formik?.values[name] || value}
                    disabled={disabled}
                    onBlur={formik?.handleBlur || onBlur}
                    onChange={formik?.handleChange || onChange}
                    value={formik?.values[name] || value}
                />
                <span className={css.slider} />
            </label>
        </InputBase>
    );
}

export default CustomSlider;
