import React, {
    useEffect,
    useRef,
    useState,
    forwardRef,
    useCallback
} from 'react';

import css from './styles.module.css';

import Streamer from './Streamer';

const NUMBER_OF_RETRIES = Infinity;

const zoomInIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none"><path d="M20 20L14.9497 14.9497M14.9497 14.9497C16.2165 13.683 17 11.933 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C11.933 17 13.683 16.2165 14.9497 14.9497ZM7 10H13M10 7V13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>
);

const zoomOutIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none"><path d="M20 20L14.9497 14.9498M14.9497 14.9498C16.2165 13.683 17 11.933 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C11.933 17 13.683 16.2165 14.9497 14.9498ZM7 10H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>
);

const centerIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none"><path d="M8 4H6C4.89543 4 4 4.89543 4 6V8M8 20H6C4.89543 20 4 19.1046 4 18V16M16 4H18C19.1046 4 20 4.89543 20 6V8M16 20H18C19.1046 20 20 19.1046 20 18V16M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
);

const fullScreenIcon = (
    <svg height="16px" version="1.1" viewBox="0 0 14 14" width="16px" xmlns="http://www.w3.org/2000/svg" sketch="http://www.bohemiancoding.com/sketch/ns" xlink="http://www.w3.org/1999/xlink"><g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#fff" id="Core" transform="translate(-215.000000, -257.000000)"><g id="fullscreen" transform="translate(215.000000, 257.000000)"><path d="M2,9 L0,9 L0,14 L5,14 L5,12 L2,12 L2,9 L2,9 Z M0,5 L2,5 L2,2 L5,2 L5,0 L0,0 L0,5 L0,5 Z M12,12 L9,12 L9,14 L14,14 L14,9 L12,9 L12,12 L12,12 Z M9,0 L9,2 L12,2 L12,5 L14,5 L14,0 L9,0 L9,0 Z" id="Shape" /></g></g></g></svg>
);

const NewCanvas = (props, ref) => {
    const {
        deviceId,
        deviceIP,
        className,
        videoClassName,
        onClick,
        hideControl,
        customEls,
        showIds,
        shouldDrawMovement,
        shouldDrawHeadPos,
        isDebugView,
        onData,
        socketClient,
        confidence = 50,
        children,
        isJSmpeg
    } = props;

    const [loading, setLoading] = useState(true);
    const [retryTimedOut, setRetryTimedOut] = useState(false);

    const imgContainerRef = useRef();
    const videoRef = useRef();
    const canvasRef = useRef();
    const streamerRef = useRef(null);
    const timeoutRef = useRef();

    const startStreamer = useCallback(async (ref, retries = NUMBER_OF_RETRIES) => {
        clearTimeout(timeoutRef.current);

        if (!deviceIP || ref.destroyed) {
            return false;
        }

        setLoading(true);
        setRetryTimedOut(false);

        ref.onFatalError = () => {
            if (ref.destroyed) return;

            if (retries > 1) {
                timeoutRef.current = setTimeout(() => startStreamer(ref, retries - 1), 1000);
            } else {
                setRetryTimedOut(true);
            }
        }

        ref.onFragLoaded = () => {
            setLoading(false);
        };

        const started = await ref.start(
            deviceIP,
            deviceId,
            isJSmpeg,
            imgContainerRef.current,
            videoRef.current,
            canvasRef.current
        );

        if (!started) {
            return false;
        }

        return started;
    }, [deviceIP, deviceId, isJSmpeg]);

    useEffect(() => {
        const ref = streamerRef.current = new Streamer();

        startStreamer(ref);

        return () => ref.stop();
    }, [startStreamer]);

    useEffect(() => {
        if (!loading && onClick) {
            streamerRef.current.onClick = onClick;
        }
    }, [loading, onClick]);

    useEffect(() => {
        if (!loading) {
            streamerRef.current.isDebugView = isDebugView;
        } else {
            streamerRef.current.isDebugView = false;
        }
    }, [loading, isDebugView]);

    useEffect(() => {
        if (!loading) {
            streamerRef.current.shouldDrawMovement = shouldDrawMovement;
        } else {
            streamerRef.current.shouldDrawMovement = false;
        }
    }, [loading, shouldDrawMovement]);

    useEffect(() => {
        if (!loading) {
            streamerRef.current.shouldDrawHeadPos = shouldDrawHeadPos;
        } else {
            streamerRef.current.shouldDrawHeadPos = false;
        }
    }, [loading, shouldDrawHeadPos]);

    useEffect(() => {
        if (!loading) {
            streamerRef.current.showIds = showIds;
        } else {
            streamerRef.current.showIds = false;
        }
    }, [loading, showIds]);

    useEffect(() => {
        if (!loading && onData) {
            streamerRef.current.onData = onData;
        } else {
            streamerRef.current.onData = () => {};
        }
    }, [loading, onData]);

    useEffect(() => {
        let dId = deviceId;

        if (!loading && socketClient && dId) {
            streamerRef.current.startSockets(socketClient, dId);
        }

        return () => {
            if (socketClient) {
                streamerRef.current.stopSockets(socketClient, dId);
            }
        }
    }, [loading, socketClient, deviceId]);

    useEffect(() => {
        if (!loading) {
            streamerRef.current.confidence = confidence;
        }
    }, [loading, confidence]);

    let loaderEl = null;
    if (retryTimedOut) {
        loaderEl = (
            <div className={css.loaderContainer}>
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: 10,
                  justifyContent: 'center',
                }}>
                    <span style={{ color: '#fff' }}>Sorry this video is currently unavailable.</span>
                    <button
                      onClick={() => startStreamer(streamerRef.current)}
                      className={css.retry}
                    >Retry</button>
                </div>
            </div>
        )
    } else if (loading) {
        loaderEl = (
            <div className={css.loaderContainer}>
                <div className={css.loader} />
            </div>
        )
    }

    const controlEl = !hideControl
        ? (
            <div className={css.btnContainer}>
                <div>
                    {customEls}
                </div>
                <div>
                    <button
                        className={css.btn}
                        onClick={() => streamerRef.current.zoomIn()}
                    >
                        {zoomInIcon}
                    </button>
                    <button
                        className={css.btn}
                        onClick={() => streamerRef.current.zoomOut()}
                    >
                        {zoomOutIcon}
                    </button>
                    <button
                        className={css.btn}
                        onClick={() => streamerRef.current.center()}
                    >
                        {centerIcon}
                    </button>
                    <button
                        className={css.btn}
                        onClick={() => streamerRef.current.toggleFullscreen()}
                    >
                        {fullScreenIcon}
                    </button>
                </div>
            </div>
        )
        : null;

    const videoCanvas = isJSmpeg
        ? (
            <canvas
                ref={videoRef}
                className={`${css.viewer} ${videoClassName || ''}`}
            />
        )
        : (
            <video
                ref={videoRef}
                className={`${css.viewer} ${videoClassName || ''}`}
                muted
                autoPlay
                playsInline
            />
        );

    return (
        <div
            className={`${css.imgContainer} ${className || ''}`}
            ref={imgContainerRef}
            tabIndex="0"
        >
            <div className={css.videoContainer}>
                {videoCanvas}
                <canvas ref={canvasRef} className={css.canvas} />
            </div>
            {loaderEl}
            {controlEl}

            {children}
        </div>
    );
};

export default forwardRef(NewCanvas);
