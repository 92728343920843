import React, {useState, useRef} from 'react';

import Viewer from '../../components/Viewer/Modal';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import Loader from '../../components/Loader/Loader';
import RemoveModal from '../../components/RemoveModal';
import CustomTable from '../../components/CustomTable';
import AlertModal from '../../components/AlertModal';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';

import Client from '../../apis/Client';

import DeviceModal from './DeviceModal';
import HeartbeatModal from './HeartbeatModal';

import css from '../styles.module.scss';

function Devices(props) {
    const { location } = props;

    const locationId = location?.id;
    const companyId = location?.company_id;

    const [selectedDevice, setSelectedDevice] = useState(null);
    const viewerRef = useRef();

    const [showCreateEditDevice, setShowCreateEditDevice] = useState(false);
    const [showRemoveDevice, setShowRemoveDevice] = useState(false);
    const [showLiveView, setShowLiveView] = useState(false);
    const [showHeartbeats, setShowHeartbeats] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    const { mutateAsync: updateDeviceMonitoring } = useMutation({
        mutationKey: 'put_device_monitoring',
        mutationFn: (device) => Client.put(`/api/v1/admin/customers/${companyId}/locations/${locationId}/devices/${device.id}/monitoring`, { monitor: !device.is_monitored }),
        onSuccess: () => {
            refetch();
        }
    });

    const {
        data: devices,
        isLoading,
        isError,
        error,
        refetch
    } = useQuery({
        queryKey: ['location_devices', locationId],
        queryFn: () => Client.get(`/api/v1/admin/customers/${companyId}/locations/${locationId}/devices`)
    });

    const { mutateAsync: removeDevice } = useMutation({
        mutationKey: 'delete_device',
        mutationFn: () => Client.delete(`/api/v1/admin/customers/${companyId}/locations/${locationId}/devices/${selectedDevice.id}`),
        onSuccess: () => {
            setShowDeleteAlert(false);
            refetch();
        }
    });

    function createNewDevice() {
        setSelectedDevice(null);
        setShowCreateEditDevice(true);
    }

    const columnsInfo = [
        { name: 'name', label: 'Name' },
        {
            name: 'latLong',
            label: 'Lat/Long',
            valFn: (val) => {
                return `${val.latitude ? val.latitude + ', ' : ''} ${
                    val.longitude ? val.longitude : ''
                }`;
            },
        },
        { name: 'device_id', label: 'Device ID' },
        {
            name: 'monitoring_area',
            label: 'Monitoring Area',
            valFn: (val) => {
                return (val.monitoring_area || {}).name || '';
            },
        },
        {
            name: 'pole',
            label: 'Pole',
            valFn: (val) => {
                return (val.pole || {}).name || '';
            },
        },
        {
            name: 'monitoring',
            label: 'Monitoring',
            width: 87,
            componentFn: (device) => {
                const className = device.is_monitored
                    ? `btn btn-primary me-sm-auto me-lg-0`
                    : `btn btn-outline-primary me-sm-auto me-lg-0`;
                return (
                    <button
                        type="button"
                        className={className}
                        onClick={(e) => {
                            e.stopPropagation();
                            updateDeviceMonitoring(device);
                        }}
                    >
                        {device.is_monitored ? 'ON' : 'OFF'}
                    </button>
                );
            }
        },
        {
            name: 'live_view',
            label: '',
            width: 87,
            componentFn: (device) => (
                <button
                    type="button"
                    className={`btn btn-outline-primary ms-sm-auto me-sm-auto me-lg-0`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDevice({ ...device });
                        setShowLiveView(true);
                    }}
                >
                    Live View 
                    <div className={`${css.onlineIcon} ${device.deviceOnline ? css.active : ''}`}/>
                </button>
            )
        },
        {
            name: 'heartbeats',
            label: '',
            width: 87,
            componentFn: (device) => (
                <button
                    type="button"
                    className={`btn btn-outline-primary ms-sm-auto me-sm-auto me-lg-0`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDevice({ ...device });
                        setShowHeartbeats(true);
                    }}
                >
                    Heartbeats
                </button>
            )
        },
        {
            name: 'deleteBtn',
            label: '',
            width: 87,
            componentFn: (device) => (
                <button
                    type="button"
                    className={`btn btn-outline-danger ms-sm-auto me-sm-auto me-lg-0`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDevice({ ...device });
                        setShowDeleteAlert(true);
                    }}
                >
                    Delete
                </button>
            )
        },
    ];

    const heartBeatHeadrsSet = useRef(new Set());

    // const devices = data || [];
    // const heartBeatData = (heartBeats || {}).data || [];
    const skipAttributes = ['id', 'bandWidth'];
    // if (heartBeatData.length) {
    //     heartBeatData.forEach((beat) => {
    //         //go two levels deep and add headers
    //         for (const prop in beat.msg) {
    //             if (skipAttributes.includes(prop)) {
    //                 continue;
    //             }
    //             let path = prop;
    //             const datum = beat.msg[prop];
    //             if (
    //                 typeof datum === 'object' &&
    //                 !Array.isArray(datum) &&
    //                 datum !== null
    //             ) {
    //                 for (const secondLevelProp in datum) {
    //                     if (skipAttributes.includes(secondLevelProp)) {
    //                         continue;
    //                     }
    //                     heartBeatHeadrsSet.current.add(
    //                         path + '.' + secondLevelProp
    //                     );
    //                 }
    //             } else {
    //                 heartBeatHeadrsSet.current.add(path);
    //             }
    //         }
    //     });
    // }

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <h4 style={{color: 'red'}}>{error.msg}</h4>;
    }

    return (
        <>
            <div id="tableContain" className={`bgWhite tableContain`}>
                <PageSubTitle title="Devices" />
                <div style={{ width: '100%', height: 'calc(200px)' }}>
                    <CustomTable
                        defaultSortCol="name"
                        columns={columnsInfo}
                        rows={devices}
                        rowKey="id"
                        onRowClick={(e, row) => {
                            setSelectedDevice({ ...row });
                            setShowCreateEditDevice(true);
                        }}
                    />
                </div>
                <button onClick={createNewDevice}>Add New</button>
            </div>

            {showLiveView && (
                <Viewer
                    ref={viewerRef}
                    deviceId={selectedDevice.id}
                    deviceIP={selectedDevice.device_ip}
                    isJSmpeg={selectedDevice?.is_rtsp}
                    className={css.liveViewContainer}
                    showIds={false}
                    isDebugView={false}
                    setShowViewer={setShowLiveView}
                    showViewer={showLiveView}
                />
            )}

            <DeviceModal
                device={selectedDevice}
                locationId={locationId}
                companyId={companyId}
                showModal={showCreateEditDevice}
                setShowModal={setShowCreateEditDevice}
                onSuccess={(rsp) => {
                    setSelectedDevice(null);
                    refetch();
                }}></DeviceModal>

            {showHeartbeats && (
                <HeartbeatModal
                    device={selectedDevice}
                    setShowModal={setShowHeartbeats}
                />
            )}

            {showDeleteAlert && (
                <AlertModal
                    onAccept={removeDevice}
                    onCancel={() => setShowDeleteAlert(false)}
                    title="Remove Device"
                    btn1="No"
                    btn2="Yes"
                    message="Would you like to remove this device?"
                />
            )}
        </>
    );
}

export default Devices;
